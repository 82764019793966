import React from 'react';
import {Helmet} from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import ASIdistributors from '../../images/asi_distributors_map2.jpg';
import Soundingrooms from '../../images/additional-resources/ebook-acoustics-demyst.webp';
import Commonnoiseprob from '../../images/additional-resources/Common-Noise-Problems-3D01x.webp';
import CAD from '../../images/additional-resources/ebook-cad-revit.webp';
import './international.css';
import ScrollToTopButton from '../ScrollButton';
function international() {
    return (
      
    <>
    <Helmet>
    <meta name="description" content="" />
    <title>Acoustical Surfaces International Offices and Distributors</title>
    <meta name="robots" content="index, follow" />
    <meta property="og:title" content="Acoustical Surfaces International Offices and Distributors" />
    <meta property="og:description" content="" />
    <meta property="og:image" content="https://www.acousticalsurfaces.in/images/logo1.png" />
    <meta property="og:locale" content="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.ae/international" hreflang="en-ae" />
    <link rel="alternate" href="https://www.acousticalsurfaces.in/international" hreflang="en-in" />
    <link rel="canonical" href="https://www.acousticalsurfaces.in/international" />
    </Helmet>
    <Header />
    <section className="content" id="international">
        <div className="container">
            <div className="row">
                <div className="col-sm-3 sidemenu">
                    <Sidebar />
                </div>
                <div className="col-sm-9 product-page nopaddding">
                    <div className="container thanks">
                        <div className="megamenu">
                            <Megamenu />
                        </div>
                        <h3>Acoustical Surfaces International Offices and Distributors</h3>
                        <div className="col-sm-12">
                            <img className="img-fluid" src={ASIdistributors} alt="Acoustical Surfaces" />
                        </div>
                        <div className="line"></div>
                        
                        <div className="row">
					        <div className="col-lg-4 col-sm-6 codeadd">
                                <h1>UAE</h1>
                                <h5>Dubai</h5>
                                <p>
                                    Moov General Trading LLC<br />
                                    Office no# B2002, Latifa Tower,<br />
                                    Sheikh Zayed Road<br />
                                    P.O Box 113239, Dubai, UAE<br /><br /><br />
                                    <b>M:</b> <a href="mailto:mail@moovgroup.com">mail@moovgroup.com</a>
                                    <br />
                                    <b>T:</b> <a href="tel:+97143310000">+971 4 331 0000</a>
                   		        </p>
                            </div>
                            <div className="col-lg-4 col-sm-6 codeadd">
                                <h1>KSA</h1>
                                <h5>Eastern Province</h5>
                                <p>
                                    GITCO-Global Innovation for Trading & <br />Contracting Company<br />
                                    P.O. Box 6174,<br />
                                    Al Khobar 34433<br />
                                    Kingdom of Saudi Arabia<br /><br />
                                    <b>M:</b> <a href="mailto:ksa@acousticalsurfaces.in">ksa@acousticalsurfaces.in</a>
                                    <br />
                                    <b>T:</b> <a href="tel:+966534690007 ">+966-534-690-007 </a> | <a href="tel:+966138331753 ">+966-13-8331753 </a><br />
                   		        </p>
                            </div>
                            <div className="col-lg-4 col-sm-6 codeadd">
                                <h1>OMAN</h1>
                                <h5>Muscat</h5>
                                <p>
                                    SMT Acoustics | Atlal al-qurum trad. <br />& cont. LLC<br />
                                    P.O. Box 2659, P.C.112, Ruwi<br />
                                    Muscat , Bldg #3427, Way #2645, <br />Al Mina St, Jibroo<br /><br />
                                    <b>M:</b> <a href="mailto:sanju@smtacoustics.com">sanju@smtacoustics.com</a>
                                    <br />
                                    <b>T:</b> <a href="tel:+96896760819">+968 96760819</a><br />
                   		        </p>
                            </div>
                            <div className="line"></div>
                            <div className="col-lg-4 col-sm-6 codeadd">
                                <h1>UAE</h1>
                                <h5>Abu Dhabi</h5>
                                <p>
                                    Moov Construction Solutions LLC<br />
                                    Office #5, ICAD-Building C<br />
						            P.O Box 132852<br />
                                    Abu Dhabi, UAE<br /><br /><br />
                                    <b>M:</b> <a href="mailto:construction@moov.ae">construction@moov.ae</a>
                                    <br />
                                    <b>T:</b> <a href="tel:+97125500104">+971 2 55 00 104</a><br />
                   		        </p>
                            </div>
                            <div className="col-lg-4 col-sm-6 codeadd">
                                <h1>BAHRAIN</h1>
                                <h5>Salmabad</h5>
                                <p>
                                    Global innovation for trading and contracting gitco w.l.l salmabad<br />
                                    Office : 15-BLDG: 49,<br />
                                    Road : 10 BLOCK: 704<br />
                                    KINGDOM OF BAHRAIN<br /><br />
                                    <b>M:</b> <a href="mailto:mail@moovgroup.com">mail@moovgroup.com</a>
                                    <br />
                                    <b>T:</b> <a href="tel:+97317786070">+973 17786070</a> | <a href="tel:+97338207698">+973 38207698</a><br />
                   		        </p>
                            </div>
                            <div className="col-lg-4 col-sm-6 codeadd">
                                <h1>KUWAIT</h1>
                                <h5>Kuwait City</h5>
                                <p>
                                    Mariyam Barjees General<br /> Trading and Contrcating Co<br />
                                    Souk Al Sabah Bldg,
                                    Office#1, Fahaheel<br />
                                    P.O Box 52272<br />
                                    Kuwait<br /><br />
                                    <b>M:</b> <a href="mailto:operations@mariyamgroup.com">operations@mariyamgroup.com</a>
                                    <br />
                                    <b>T:</b> <a href="tel:+96566959503 ">+965-66959503 </a><br />
                   		        </p>
                            </div>
                        </div> 
  				        <div className="line"></div>
				        <div className="row">
					        <div className="col-lg-4 col-sm-6 codeadd">
                                <h1>QATAR</h1>
                                <h5>Doha</h5>
                                <p>
                                    Al Emadi Trading & Contracting Co<br />
                                    P.O.Box : 5217, Unit No. C201, 2nd Floor,  <br />
                                    Mirqab Mall,<br />
                                    Al Nasar, Doha, Qatar<br /><br /><br />
                                    <b>M:</b> <a href="mailto:contact@alemadicontracting.com">contact@alemadicontracting.com</a> 
                                    <br />
                                    <b>T:</b> <a href="tel:+97444582614">+974 4458 2614</a> | <a href="tel:0097433273086">00974-33273086</a><br />
                   		        </p>
                            </div>
                            <div className="col-lg-4 col-sm-6 codeadd">
                                <h1>QATAR</h1>
                                <h5>Doha</h5>
                                <p>
                                    Arselona Trading and Contracting Wll<br />
                                    Zone 90, Street 720, Building 48, Second floor,   <br />
                                    Office no.217, Wakra Boulevard Business Centre,<br />
                                    Wakra, Doha, Qatar<br /><br />
                                    <b>T:</b> <a href="tel:+97470400828">+974 7040 0828</a>
                                    <br />
                                    <b>T:</b> <a href="tel:+97444867028">+974 4486 7028</a><br />
                   	            </p>
                            </div>
                            <div className="col-lg-4 col-sm-6 codeadd">
                                <h1>UK</h1>
                                <h5>Dagenham</h5>
                                <p>
                                    SM6 LTD<br />
                                    27 Mayesbrook Road,<br />
                                    Dagenham,<br />
                                    Essex RM8 2 EA, <br />
                                    United Kingdom<br />
                                    <br />
                                    <b>T:</b> <a href="tel:+4407886460881">+44 (0) 7886460881</a>
                                    <br />
                                    <br />
                   		        </p>
                            </div>
                        </div> 
                        <div className="line"></div>
				        <div className="row">
					        <div className="col-lg-4 col-sm-6 codeadd">
                                <h1>AFRICA</h1>
                                <h5>Nigeria</h5>
                                <p>
                                    MY RECHUSH GLOBAL CONCEPT LTD (MRGL)<br />
                                    Suite D19, International Building Complex,  <br />
                                    Lungi Barrack Fct Abuja,<br />
                                    Africa<br /><br /><br />
                                    <b>M:</b> <a href="mailto:admin@mrglacoustic.com">admin@mrglacoustic.com</a> 
                                    <br />
                                    <b>T:</b> <a href="tel:+2348060265041 ">+2348060265041 </a><br />
                   		        </p>
                            </div>
                            <div className="col-lg-4 col-sm-6 codeadd">
                                <h1>EUROPE</h1>
                                <h5>Balkans</h5>
                                <p>
                                    INTERACTIVE D.O.O<br />
                                    Ul.Djoka Mirasevica br.82,  <br />
                                    81000,<br />
                                    Podgorica, Montenegro, Balkans<br /><br />
                                    <br />
                		            <b>M:</b> <a href="mailto:andrej.sedlak@interactive.co.me">andrej.sedlak@interactive.co.me</a> 
                                    <br />
                                    <b>T:</b> <a href="tel:+38220653555">+382 20 65 35 55</a><br /><a href="tel:+38267653555">+38267653555</a> | <a href="tel:+38267477888">+38267477888</a><br />
                   		        </p>
                            </div> 
                        </div>  
					    <div className="col-md-12 inner-page distbanner">
					        <div className="row">
							    <div className="box1 text-center">
							        <h1>TO SUCCEED WITH US !</h1>
								    <p>We are looking for distributors around the Globe.<br /><b><Link to="mailTo:mail@acousticalsurfaces.in">mail@acousticalsurfaces.in</Link></b></p>
							    </div>
						    </div>
					    </div>
                        <div className="row resources">
                            <div className="col-xl-12 col-lg-12 col-sm-12">
                                <h2>Additional Resources</h2>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-sm-12">
                                <h6 style={{textalign: "center"}}>Creating Better-Sounding Rooms</h6>
                                <img className="hvr-grow" src={Soundingrooms} alt="Create Better Sounding Rooms" target="_blank" />
                                <div className="butn">
                                    <a href="https://api.whatsapp.com/send?phone=918277331000&text=Hi" target="_blank" rel="noopener noreferrer">Download eBook</a>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-sm-12">
                                <h6 style={{textalign: "center"}}>Solutions to Common Noise Problems</h6>
                                <img className="hvr-grow" src={Commonnoiseprob} alt="Common Noise Problems" target="_blank" />
                                <div className="butn">
                                    <a href="https://api.whatsapp.com/send?phone=918277331000&text=Hi" target="_blank" rel="noopener noreferrer">Download eBook</a>
                                </div>
                            </div>
                            <hr />
                            <div className="col-xl-4 col-lg-4 col-sm-12">
                                <h6 style={{textalign: "center"}}><br />CAD, CSI, & Revit Library </h6>
                                <img className="hvr-grow" src={CAD} alt="CAD Library" target="_blank" />
                                <div className="butn">
                                    <a href="https://api.whatsapp.com/send?phone=918277331000&text=Hi" target="_blank" rel="noopener noreferrer">Access Product Cad Files</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ScrollToTopButton />
    </section>
<Footer />
</>
);
}
export default international; 