import React from 'react';
import {Helmet} from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Ebook from '../../images/additional-resources/cad-library.jpg';
import './cad-library-guide.css';
import ScrollToTopButton from '../ScrollButton';
function cadlib() {
    return (
      
    <>
    <Helmet>
    <title>Access our CAD, CSI, Revit Library - Acoustical Surfaces</title>
    <meta name="description" content="" />
    <meta property="og:title" content="Access our CAD, CSI, Revit Library" />
    <meta property="og:description" content="Here at Acoustical Surfaces, we keep a number of CAD, CSI and Revit files on hand for our customers." />
    <meta property="og:image" content="https://www.acousticalsurfaces.in/images/additional-resources/cad-library.jpg" />
    <meta property="og:locale" content="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.ae/cad-library-guide" hreflang="en-ae" />
    </Helmet>
    <Header />
    <div className='line'></div>
    <section className="content" id="cadlibrary">
        <div className="container">
            <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12">
                    <h3>Access our CAD, CSI, Revit Library</h3>
                    <p><br />Here at Acoustical Surfaces, we keep a number of CAD, CSI and Revit files on hand for our customers. </p>
                    <div className="row">
                        <div className="col-lg-7 col-md-7 col-sm-12">
                            <p>You'll find files associated with some of our most popular products including:</p>
                            <ul style={{marginLeft: "2em"}}>
                                <li>Echo Eliminator™</li>
                                <li>Sound Silencer™</li>
                                <li>CFAB™ Cellulose Panels</li>
                                <li>Microperf Acousticore Panels</li>
                                <li>Acoustical Window Inserts</li>
                                <li>PermaTURFMicro </li>
                            </ul>
                        </div>
                        <div className="col-lg-5 col-md-5 col-sm-12">
                            <img src={Ebook} alt="eBook" className="img-fluid" />
                        </div>
                    </div>
                    <p><br />Simply submit the form on the right for access. As always, please don't hesitate to <Link to="/contact-us">contact us</Link> should you have any questions.</p>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 form">
                    <h4>Fill out the form to Access Resources Library</h4>
                    <div className="enqform">          
                        <form action="dl-script-cad_library.php" method="post" className="enquiry-form">
                        <div className="">
                            <input className="form-control" type="text" name="inputName" placeholder="Name" required="" />
                        </div>
                        <div className="">
                            <input className="form-control" type="text" name="inputCompany" placeholder="Company" required="" />
                        </div>
                        <div className="">
                            <input className="form-control" type="tel" name="inputTelephone" placeholder="Telephone" required="" />
                        </div>
                        <div className="">
                            <input className="form-control" type="email" name="inputEmail" placeholder="Email" required="" />
                        </div>
                        <div className="">
                            <input type="hidden" name="inputMessage" value="Accessed CAD, CSI, Revit Library" />
                        </div>
                        <Link to="mailto:mail@acousticalsurfaces.in">Submit</Link>
                        </form>
                    </div>    
                </div>
                <div className="line"></div>
            </div>                
        </div>
        <ScrollToTopButton />
    </section>
<Footer />
</>
);
}
export default cadlib;