import React from 'react';
import {Helmet} from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import './soundproofing-fixes1.css';
import Ebook from '../../images/additional-resources/soundproofing-fixes-1-3d-cover@01x.png';
import ScrollToTopButton from '../ScrollButton';
function soundprooffixes1() {
    return (
      
    <>
    <Helmet>
    <title>Easy Soundproofing Fixes - Acoustical Surfaces </title>
    <meta name="description" content="" />
    <meta property="og:title" content="Easy Soundproofing Fixes" />
    <meta property="og:description" content="Each of the solutions includes the product, benefits, applications, and next-to-best solutions accordingly." />
    <meta property="og:image" content="https://www.acousticalsurfaces.in/images/logo1.png" />
    <meta property="og:locale" content="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.ae/soundproofing-fixes_1" hreflang="en-ae" />
    <link rel="canonical" href="https://www.acousticalsurfaces.in/soundproofing-solutions/soundproofing-fixes_1" />
    <link rel="alternate" href="https://www.acousticalsurfaces.in/soundproofing-solutions/soundproofing-fixes_1" hreflang="en-in" />
    <meta name="geo.placename" content="India" />
    <meta http-equiv="content-language" content="IN" />
    <meta name="geo.region" content="india" />
    </Helmet>
    <Header />
    <section className="content downloadpage" id="fixes1">
        <div className="container">
            <hr />
            <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12">
                    <h3>Easy Soundproofing Fixes - Part 1</h3>
                    <p style={{color: "#f63340"}}><strong>The Big 3 for Surfaces: Doors, Windows, Pipes & Ducts</strong></p>
                    <div className="row">
                        <div className="col-lg-7 col-md-7 col-sm-12">
                            <p>Learn about the foremost noise issues and their ideal fix in Acoustical Surfaces' latest guide. Each of the solutions includes the product, benefits, applications, and next-to-best solutions accordingly.</p>
                            <p><strong>Structural soundproofing solutions for:</strong></p>
                            <ul style={{marginleft: "2em"}}>
                                <li>Doors</li>
                                <li>Windows</li>
                                <li>Pipes & Ducts</li>
                            </ul>
                            <p style={{color: "#f63340"}}>Download your copy of Easy Soundproofing Fixes - Part 1 by filling out the form on on this page.</p>
                        </div>
                        <div className="col-lg-5 col-md-5 col-sm-12">
                            <img src={Ebook} alt="eBook" className="img-fluid" />
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 form">
                    <h4>Fill out the form to download your eBook</h4>
                    <div className="enqform">           
                        <form action="/dl-script-easy_Soundproofing_pt1" method="post" className="enquiry-form">
                            <div className="">
                                <input className="form-control" type="text" name="inputName" placeholder="Name" required="" />
                            </div>
                            <div className="">
                                <input className="form-control" type="text" name="inputCompany" placeholder="Company" required="" />
                            </div>
                            <div className="">
                                <input className="form-control" type="tel" name="inputTelephone" placeholder="Telephone" required="" />
                            </div>
                            <div className="">
                                <input className="form-control" type="email" name="inputEmail" placeholder="Email" required="" />
                            </div>
                            <div className="">
                                <input type="hidden" name="inputMessage" value="Easy Soundproofing Fixes - Part 1 Download" />
                            </div>
                            <Link to="mailto:mail@acousticalsurfaces.in" type="submit" name="submit_form" className="btn-send">Submit</Link>
                        </form>
                    </div>     
                </div>
                <div className="line"></div>
            </div>
        </div>
        <ScrollToTopButton />
    </section>
<Footer />
</>
);
}
export default soundprooffixes1; 