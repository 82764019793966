import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import ASIoffice from '../../images/asi_distributors_map2.jpg';
import Soundingrooms from '../../images/additional-resources/ebook-acoustics-demyst.webp';
import Commonnoiseprob from '../../images/additional-resources/Common-Noise-Problems-3D01x.webp';
import CAD from '../../images/additional-resources/ebook-cad-revit.webp';
import './national.css';
import ScrollToTopButton from '../ScrollButton';
function national() {
    return (

        <>
            <Helmet>
                <title>Acoustical Surfaces International Offices and Distributors</title>
                <meta name="description" content="" />
                <meta property="og:title" content="" />
                <meta property="og:description" content="" />
                <meta property="og:image" content="https://www.acousticalsurfaces.in/images/logo1.png" />
                <meta property="og:locale" content="en-in" />
                <link rel="alternate" href="https://www.acousticalsurfaces.ae/national" hreflang="en-ae" />
                <link rel="alternate" href="https://www.acousticalsurfaces.in/national" hreflang="en-in" />
                <link rel="canonical" href="https://www.acousticalsurfaces.in/national" />
            </Helmet>
            <Header />
            <section className="content" id="national">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-3 sidemenu">
                            <Sidebar />
                        </div>
                        <div className="col-sm-9 product-page nopaddding">
                            <div className="container thanks">
                                <div className="megamenu">
                                    <Megamenu />
                                </div>
                                <h3>Acoustical Surfaces International Offices and Distributors</h3>
                                <div className="col-sm-12">
                                    <img className="img-fluid" src={ASIoffice} alt="Acoustical Surfaces International Offices" />
                                </div>
                                <div className="line"></div>
                                <div className="row">
                                    <div className="col-lg-4 col-sm-6 codeadd">
                                        <h1>TAMILNADU</h1>
                                        <h5>Chennai</h5>
                                        <p>
                                            Cubix Marketing<br />
                                            Alsa Towers, 186, Poonamallee High Road,<br />
                                            Kilpauk, Chennai – 600 010<br />
                                            <br />
                                            <br />
                                            <br />
                                            <b>T:</b><Link to="tel:+919345913112">+91-9345913112</Link><br />
                                            <b>M:</b> <a href="mailto:sales@cubixmarketing.in">sales@cubixmarketing.in</a>
                                        </p>
                                    </div>
                                    <div className="col-lg-4 col-sm-6 codeadd">
                                        <h1>TAMILNADU</h1>
                                        <h5>Tirupur</h5>
                                        <p>
                                            Concept Home Cinemas<br />
                                            #7, Ragul Complex, 2nd Floor,<br />
                                            Near Revathi Medical Center,<br />
                                            Valayankadu Main Road, Kumar Nagar,<br />
                                            Tirupur-641603
                                            <br />
                                            <br />
                                            <b>T:</b><Link to="tel:+919894453583">+91-9894453583</Link>
                                            <br />
                                            <b>M:</b><Link to="mailto:concepthomecinemas@gmail.com">concepthomecinemas@gmail.com</Link>
                                            <br />
                                        </p>
                                    </div>
                                    <div className="col-lg-4 col-sm-6 codeadd">
                                        <h1>KARNATAKA</h1>
                                        <h5>Bangalore</h5>
                                        <p>
                                            Dhanvi Enterprises<br />
                                            No.28A, 5th Cross, 3rd Block,<br />
                                            Nagarabhavi, Bangalore,<br />
                                            Karnataka-560072<br />
                                            <br />
                                            <br />
                                            <b>T:</b> <a href="tel:+91  9845349658">+91-9845349658</a><br />
                                            <b>M:</b> <a href="mailto:dhanvi.grp@gmail.com">dhanvi.grp@gmail.com</a>
                                            <br />
                                        </p>
                                    </div>
                                    <div className="line"></div>
                                    <div className="col-lg-4 col-sm-6 codeadd">
                                        <h1>TELANGANA</h1>
                                        <h5>Hyderabad</h5>
                                        <p>
                                            ASA Global Interior Solutions<br />
                                            404, Street No. 28, Beside BBR Hospital,<br />
                                            above Karuana Motorcycles,<br /> Firozguda, Balanagar,<br />
                                            Hyderabad, Telangana-500011<br /><br />
                                            <b>T:</b> <a href="tel:+919866444155">+91-9866444155</a><br />
                                            <b>M:</b> <a href="mailto:asaglobalinteriors@gmail.com">asaglobalinteriors@gmail.com</a>
                                        </p>
                                    </div>
                                    <div className="col-lg-4 col-sm-6 codeadd">
                                        <h1>KARNATAKA</h1>
                                        <h5>Bangalore</h5>
                                        <p>
                                            Bumada Building Solutions<br />
                                            687, 3m, Ombr Layout,<br />
                                            Bangalore,<br />
                                            Karnataka-560043<br /><br />
                                            <br />
                                            <b>T:</b> <a href="tel:080-48509419">080-48509419</a> | <a href="tel:+919900519977">+91-9900519977</a><br />
                                            <b>M:</b> <a href="mailto:projects@bumada.in">projects@bumada.in</a> | <a href="mailto:abhik@bumada.in">abhik@bumada.in</a>
                                        </p>
                                    </div>
                                    <div className="col-lg-4 col-sm-6 codeadd">
                                        <h1>WEST BENGAL</h1>
                                        <h5>Kolkata</h5>
                                        <p>
                                            Smart Living Solutions<br />
                                            Block-F, No. 110, B.P<br />
                                            Township,<br />
                                            Kolkata-700094<br /><br />
                                            <br />
                                            <b>T:</b> <a href="tel:+919163817622">+91-9163817622 </a><br />
                                            <b>M:</b> <a href="mailto:smartlivingsolution@gmail.com">smartlivingsolution@gmail.com</a>
                                        </p>
                                    </div>
                                </div>
                                <div className="line"></div>
                                <div className="row">
                                    <div className="col-lg-4 col-sm-6 codeadd">
                                        <h1>MAHARASHTRA</h1>
                                        <h5>Mumbai</h5>
                                        <p>
                                            Avante Interior Concepts LLP<br />
                                            B 805, Ambar Lok Rachana Complex,<br />
                                            Amar Nagar, Mulund(W), Mumbai City, <br />
                                            Mumbai, Maharashtra-400082<br /><br /><br />
                                            <b>T:</b> <a href="tel:+919004929894">+91-9004929894</a><br />
                                            <b>M:</b> <a href="mailto:mohan@designavante.in">mohan@designavante.in</a>
                                        </p>
                                    </div>
                                    <div className="col-lg-4 col-sm-6 codeadd">
                                        <h1>KARNATAKA</h1>
                                        <h5>Bangalore</h5>
                                        <p>
                                            Astik Home Cinemas, Shop No.105,<br />
                                            Ff, Balaji Arcade, Goaves Circle,<br />
                                            Above Toyota Showroom,<br />
                                            Belagavi-590001<br /><br />
                                            <br />
                                            <b>T:</b> <a href="tel:+919513490007">+91-9513490007</a>
                                            <br />
                                            <b>M:</b> <a href="mailto:astikhomecinema@gmail.com">astikhomecinema@gmail.com</a>
                                        </p>
                                    </div>
                                    <div className="col-lg-4 col-sm-6 codeadd">
                                        <h1>TELANGANA</h1>
                                        <h5>Hyderabad</h5>
                                        <p>
                                            Oshine<br />
                                            7-1-69/1, Dharamkaram Road,<br />
                                            Ameerpet, Near Ratnadeep Supermarket,<br />
                                            Hyderabad-500016 <br />
                                            <br />
                                            <br />
                                            <b>T:</b> <a href="tel:+919666664517">+91-9666664517</a> |  <a href="tel:+918801606080">+91-8801606080</a><br />
                                            <b>M:</b> <a href="mailto:info@oshine.in">info@oshine.in</a>
                                        </p>
                                    </div>
                                </div>
                                <div className="line"></div>
                                <div className="row">
                                    <div className="col-lg-4 col-sm-6 codeadd">
                                        <h1>GUJARAT</h1>
                                        <h5>GUJARAT</h5>
                                        <p>
                                            Parikh Hardware & Paints<br />
                                            Hotel, Old, Gf-61 Omkar 2 Complex,<br />
                                            Station Road, Nh O8 Road,<br />
                                            Near Lords, Plaza,<br />
                                            Ankleshwar, Gujarat-393001<br />
                                            <br />
                                            <b>T:</b> <a href="tel:+918128896610">+91-8128896610</a><br />
                                        </p>
                                    </div>
                                    <div className="col-lg-4 col-sm-6 codeadd">
                                        <h1>KARNATAKA</h1>
                                        <h5>Bangalore</h5>
                                        <p>
                                            Smart Home Cinema<br />
                                            No.35/33, 11th Main Road,<br />
                                            5th Block, Jayanagar, <br />
                                            Bangalore-560011<br /><br />
                                            <br />
                                            <b>T:</b> <a href="tel:+916366777711">+91-6366777711</a> | <a href="tel:+919845154455">+91-9845154455</a><br />
                                            <b>M:</b><a href="mailto:anilkumar@smarthomeconcepts.co.in">anilkumar@smarthomeconcepts.co.in</a>
                                            <br />

                                        </p>
                                    </div>
                                </div>
                                <div className="line"></div>
                                <div className="col-md-12 distbanner">
                                    <div className="row">
                                        <div className="box1 text-center">
                                            <h1>TO SUCCEED WITH US !</h1>
                                            <p>We are looking for distributors around the Globe.<br />
                                                <b><Link to="mailto:mail@acousticalsurfaces.in">mail@acousticalsurfaces.in</Link></b>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row resources">
                                    <div className="col-xl-12 col-lg-12 col-sm-12">
                                        <h2>Additional Resources</h2>
                                    </div>
                                    <div className="col-xl-4 col-lg-4 col-sm-12">
                                        <h6 style={{ textalign: "center" }}>Creating Better-Sounding Rooms</h6>
                                        <img className="hvr-grow" src={Soundingrooms} alt="Create Better Sounding Rooms" target="_blank" />
                                        <div className="butn">
                                            <a href="https://api.whatsapp.com/send?phone=918277331000&text=Hi" target="_blank" rel="noopener noreferrer">Download eBook</a>
                                        </div>
                                    </div>
                                    <div className="col-xl-4 col-lg-4 col-sm-12">
                                        <h6 style={{ textalign: "center" }}>Solutions to Common Noise Problems</h6>
                                        <img className="hvr-grow" src={Commonnoiseprob} alt="Common Noise Problems" target="_blank" />
                                        <div className="butn">
                                            <a href="https://api.whatsapp.com/send?phone=918277331000&text=Hi" target="_blank" rel="noopener noreferrer">Download eBook</a>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="col-xl-4 col-lg-4 col-sm-12">
                                        <h6 style={{ textalign: "center" }}><br />CAD, CSI, & Revit Library </h6>
                                        <img className="hvr-grow" src={CAD} alt="CAD Library" target="_blank" />
                                        <div className="butn">
                                            <a href="https://api.whatsapp.com/send?phone=918277331000&text=Hi" target="_blank" rel="noopener noreferrer">Access Product Cad Files</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ScrollToTopButton />
            </section>
            <Footer />
        </>
    );
}
export default national; 