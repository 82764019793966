import React from 'react';
import {Helmet} from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import Ebook from '../../images/additional-resources/Common-Noise-Problems-3D01x.webp';
import './noise-problems-guide.css';
import ScrollToTopButton from '../ScrollButton';
function noiseprob() {
    return (
      
    <>
    <Helmet>
    <title>Common Noise Problems and Helpful Product Solutions</title>
    <meta name="description" content="" />
    <meta property="og:title" content="Common Noise Problems and Helpful Product Solutions" />
    <meta property="og:description" content="Our sound experts have created an eBook to help teach you more about noise." />
    <meta property="og:image" content="https://www.acousticalsurfaces.in/images/additional-resources/Common-Noise-Problems-3D01x.webp" />
    <meta property="og:locale" content="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.ae/noise-problems-guide" hreflang="en-ae" />
    <link rel="alternate" href="https://www.acousticalsurfaces.in/noise-problems-guide" hreflang="en-in" />
    <link rel="canonical" href="https://www.acousticalsurfaces.in/noise-problems-guide" />
    </Helmet>
    <Header />
    <section className="content" id="guide">
        <div className="container">
            <div className="row">
                <div className="col-sm-3 sidemenu">
                    <Sidebar />
                </div>
                <div className="col-sm-9 product-page nopaddding">
                    <div className="container thanks">
                        <div className="megamenu">
                            <Megamenu />
                        </div>
                        <div className='row'>
                            <div className="col-lg-6 col-md-6 col-sm-12">
                                <h3>Download Your Free Guide to Common Noise Problems</h3>
                                <p>At Acoustical Surfaces, we understand that noise problems are unfortunate and unavoidable occurrences, but they are not beyond control.<br /><br />Our sound experts have created an eBook to help teach you more about noise, its causes, and how to combat it— <strong>Common Noise Problems and Helpful Product Solutions.</strong> Inside you will learn:</p>
                                <div className="row">
                                    <div className="col-lg-7 col-md-7 col-sm-12">
                                        <p style={{marginleft: "1em"}}>Causes and Considerations of Outside Noise vs. Inside Noise<br /><br />
                                            Reducing Echoes Within a Room<br /><br />
                                            Importance of Blocking Sound and Soundproofing<br /><br />
                                            Noise Solutions for Specific Locations<br /><br />
                                            Helpful Products to Solve Noise Problems
                                        </p>
                                    </div>
                                    <div className="col-lg-5 col-md-5 col-sm-12">
                                        <img src={Ebook} alt="eBook" className="img-fluid" />
                                    </div>
                                </div>
                                <p>Download<strong> Common Noise Problems and Helpful Product Solutions.</strong> today to learn more about reducing the noise in your life. </p>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12 form">
                                <h4>Fill out the form to download your Guide</h4>
                                <div className="enqform">           
                                    <form action="dl-script-common-noise-problems.php" method="post" className="enquiry-form">
                                    <div className="">
                                        <input className="form-control" type="text" name="inputName" placeholder="Name" required="" />
                                    </div>
                                    <div className="">
                                        <input className="form-control" type="text" name="inputCompany" placeholder="Company" required="" />
                                    </div>
                                    <div className="">
                                        <input className="form-control" type="tel" name="inputTelephone" placeholder="Telephone" required="" />
                                    </div>
                                    <div className="">
                                        <input className="form-control" type="email" name="inputEmail" placeholder="Email" required="" />
                                    </div>
                                    <div className="">
                                        <input type="hidden" name="inputMessage" value="Common Noise Problems and Helpful Product Solutions Guide Download" />
                                    </div>
                                    <Link to="mailto:mail@acousticalsurfaces.in">Submit</Link>
                                    </form>
                                </div>     
                            </div>
                            <div className="line"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>   
        <ScrollToTopButton />         
    </section>
<Footer />
</>
);
}
export default noiseprob;